import axios from 'axios';
import { isDev, state } from '@/main';

export const refreshBalance = async () => {
    if (state.stacksLoggedIn === false) {
        return;
    }

    const stacksApi = isDev ? "https://stacks-node-api.testnet.stacks.co" : "https://stacks-node-api.stacks.co";

    try {
        const response = await axios.get(`${stacksApi}/extended/v1/address/${state.stacksUser}/balances`);

        state.userStacksBalance = Number((Number(response.data.stx.balance) / 1000000).toFixed(2));
    } catch (error) {
        console.log('get account balance error ', error.message);
    }
};