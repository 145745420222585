import { state } from '@/main';
import axios from 'axios';

// https://stacks-node-api.mainnet.stacks.co/extended/v1/tx/mempool?address=SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.triggerswap-v5
// https://stacks-node-api.mainnet.stacks.co/extended/v1/tx/mempool?address=SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stxswap_v10

export const checkPending = async () => {
    if (!state.stacksLoggedIn || state.pendingModalClosed) {
        return;
    }

    console.log('check pending', state.stacksUser);

    const mempool = 'https://stacks-node-api.mainnet.stacks.co/extended/v1/tx/mempool';

    const res = await axios.get(mempool + '?address=SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.stxswap_v10');

    for (let tx of res.data.results) {
        const newTx = state.pending.filter(e => e.tx_id === tx.tx_id).length < 1;

        if (newTx && tx?.contract_call?.function_args[3]?.repr === state.stacksUser) {
            state.pending.push(tx);
        }
    }

    const triggerswap = await axios.get(mempool + '?address=SP2507VNQZC9VBXM7X7KB4SF4QJDJRSWHG4V39WPY.triggerswap-v5');

    for (let tx of triggerswap.data.results) {
        const newTx = state.pending.filter(e => e.tx_id === tx.tx_id).length < 1;

        if (newTx && tx?.sender_address === state.stacksUser) {
            state.pending.push(tx);
        }
    }
};
