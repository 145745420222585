<template>
  <div class="wallet" v-if="state.stacksLoggedIn">
    <router-link to="/#mint" class="button yellow">Mint Now</router-link>
    <span class="user-icon wallet-open" @click="openDropDown">
      <Icon name="user" />
    </span>
    <div v-if="showDropdown" class="dropdown wallet-open">
      <div class="content">
        <p>{{ state.stacksAddress }}</p>
        <p>{{ state.userStacksBalance }} STX</p>
      </div>
      <div @click="stacksLogout" class="logout">Logout</div>
    </div>
  </div>
  <div v-else class="connect">
    <button @click="stacksLogin" class="button yellow">Connect</button>
  </div>
</template>

<script>
import { state, userSession } from '@/main';
import { ref } from 'vue';
import Icon from '@/js/components/Icon';
import { stacksLogin } from '@/js/helpers/stacksLogin';
import { refreshBalance } from '@/js/helpers/refreshBalance';
import { checkPending } from '@/js/helpers/checkPending';

export default {
  components: {
    Icon
  },
  async setup() {
    const showDropdown = ref(false);

    await refreshBalance();

    const openDropDown = () => {
      if (showDropdown.value) {
        return;
      }

      document.body.addEventListener('click', handleClickOutside);
      showDropdown.value = true;
    };

    const closeDropDown = () => {
      document.body.removeEventListener('click', handleClickOutside);
      showDropdown.value = false;
    };

    const handleClickOutside = (e) => {
      if (!e.target.closest('.wallet-open')) {
        closeDropDown();
      }
    };

    const stacksLogout = (e) => {
      e.stopPropagation();

      userSession.signUserOut();

      state.userData = null;
      state.stacksUser = '';
      state.stacksLoggedIn = false;
    };

    setInterval(async () => {
      await checkPending();
    }, 20000);

    await checkPending();

    return {
      state,
      showDropdown,
      stacksLogin,
      stacksLogout,
      openDropDown
    };
  }
};
</script>