<template>
  <div class="nft-gallery">
    <div v-if="state.nfts.length > 0">
      <p>Below you will see your NFTs from the Built With NFT collection. Click on one to take you to the marketplace.</p>
      <ul>
        <li v-for="id of paginated" :key="id">
          <a :href="`https://stxnft.com/${state.stacksUser}`" target="_blank">
            <img :src="`https://api.builtwithnft.org/token/${id}/small/image.jpg`" :alt="`Built With NFT #${id}`">
            <span>#{{ id }}</span>
          </a>
        </li>
      </ul>
      <div class="buttons" style="margin-top: 10px">
        <button @click="prev" class="button" :disabled="prevDisabled">Prev</button>
        <button @click="next" class="button" :disabled="nextDisabled">Next</button>
      </div>
    </div>
    <div v-else>
      You have not minted any NFTs yet.
    </div>

  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isDev, state } from '@/main';
import { refreshNftBalance } from '@/js/helpers/refreshNftBalance';

export default {
  components: {},
  async setup() {
    const current = ref(1);
    const pageSize = ref(2);

    const indexStart = computed(() => {
      return (current.value - 1) * pageSize.value;
    });

    const indexEnd = computed(() => {
      return indexStart.value + pageSize.value;
    });

    const paginated = computed(() => {
      return state.nfts.slice(indexStart.value, indexEnd.value);
    });

    const prevDisabled = computed(() => {
      return indexStart.value < 1;
    });

    const nextDisabled = computed(() => {
      return indexEnd.value >= state.nfts.length;
    });

    const refreshRegularly = async () => {
      if (isDev) {
        return;
      }

      let interval;
      try {
        interval = setInterval(async function () {
          await refreshNftBalance();
        }, 10000);
      } catch (error) {
        console.log('refreshRegularly error ', error.message);
        clearInterval(interval);
      }
    };

    const prev = () => {
      if (indexStart.value < 1) {
        console.log('nope');
        return;
      }

      current.value--;
    };

    const next = () => {
      if (indexEnd.value > state.nfts.length) {
        console.log('nope');
        return;
      }

      current.value++;
    };

    await refreshRegularly();

    return {
      state,
      paginated,
      prevDisabled,
      nextDisabled,
      prev,
      next
    };
  }
};
</script>