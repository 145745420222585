<template>
  <div id="student-cards" class="page-width">
    <div class="card-deck">
      <div v-for="image of selected" :key="image" class="card"><img :src="getHeadImgUrl(image)" alt="Student Artwork"></div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { getHeadImgUrl } from '@/js/helpers/getHeadImgUrl';
import { state } from '@/main';

const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default {
  setup() {
    const total = 15;
    const max = 5;
    const selected = ref([]);

    const randomize = () => {
      selected.value = [];

      for (let i = 0; i < max; i++) {
        let rand;

        do {
          rand = getRandom(1, total);
        }
        while (selected.value.indexOf(rand) > -1);

        selected.value.push(rand);
      }
    };

    randomize();

    clearInterval(state.headTimer);

    state.headTimer = setInterval(randomize, 8000);

    return {
      getHeadImgUrl,
      selected
    };
  }
};
</script>
