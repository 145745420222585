import { createApp, reactive } from 'vue';
import App from '@/js/components/App';
import router from './router';
import { AppConfig, UserSession } from '@stacks/connect';
import { isMetaMaskInstalled } from '@/js/helpers/isMetaMaskInstalled';

fetch('/icons.svg').then(response => response.text()).then(data => {
    document.body.insertAdjacentHTML('afterbegin', data);
});

const appConfig = new AppConfig(["store_write", "publish_data"]);
export const userSession = new UserSession({appConfig});

export const isDev = process.env.NODE_ENV === "development";
export const stacksContractAddress = isDev ? 'STNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTF0V5FDF.builtwithnft-3' : 'SP1N134B2Z1PZQVNBYVKMGND5H5PHY3ZH6EK8TASP.built-with-nft';
export const ethContractAddress = isDev ? '0x63e0cd76d11da01aef600e56175523ad39e35b01' : '0x15870e67DE672d1dbdCE72f4F4e7365CDDe22DaF';

if (window.ethereum) {
    window.ethereum.on('accountsChanged', async () => {
        if (state.ethUser == null) {
            return;
        }

        window.location.reload();
    });

    window.ethereum.on('chainChanged', async () => {
        window.location.reload();
    });
}

export const state = reactive({
    price: 85000000,
    showGalleryModal: false,
    userData: null,
    headTimer: 0,
    userStacksBalance: 0,
    nfts: [],
    pending: [],
    isInstalled: isMetaMaskInstalled(),
    stacksUser: '',
    stacksAddress: '',
    stacksLoggedIn: false,
    pendingModalClosed: false,
    icon: 'https://api.builtwithnft.org/images/fav.png',
    ethConnected: false,
    ethUser: null
});

createApp(App).use(router).mount('#app');
