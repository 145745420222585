<template>
  <ProgressBar
      :current="total"
      :total="10000"
  />
</template>

<script>
import ProgressBar from './ProgressBar';
import { getStacksProgress } from '../helpers/getStacksProgress';
import { getEthProgress } from '../helpers/getEthProgress';
import { ref } from 'vue';
import { isDev } from '@/main';

export default {
  components: {
    ProgressBar
  },
  async setup() {
    const stacksTotal = ref(0);
    const ethTotal = ref(0);
    const total = ref(0);

    const refresh = async () => {
      stacksTotal.value = await getStacksProgress();
      ethTotal.value = await getEthProgress();

      total.value = stacksTotal.value + ethTotal.value;
    };

    await refresh();

    if (isDev === false) {
      setInterval(async () => {
        await refresh();
      }, 60 * 1000);
    }

    return {
      total
    };
  }
};
</script>
