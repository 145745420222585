import axios from 'axios';
import { isDev, stacksContractAddress, state } from '@/main';

export const refreshNftBalance = async () => {
    if (!state.stacksLoggedIn) {
        state.nfts = [];
        return;
    }

    const stacksApi = isDev ? "https://stacks-node-api.testnet.stacks.co" : "https://stacks-node-api.stacks.co";

    try {
        const source = axios.CancelToken.source();

        const timeout = setTimeout(() => {
            source.cancel();
            state.nfts = [];
        }, 6000);

        const response = await axios.get(`${stacksApi}/extended/v1/tokens/nft/holdings?principal=${state.stacksUser}&limit=200`, {cancelToken: source.token});

        clearTimeout(timeout);

        let result = [];

        response.data.results.filter((item) => {
            let id = Number(item.value.repr.replace('u', ''));

            if (item.asset_identifier === stacksContractAddress + '::builtwithnft' && !result.includes(id)) {
                result.push(id);
            }
        });

        state.nfts = result;
    } catch (error) {
        console.log('get nft holdings error ', error.message);
    }
};