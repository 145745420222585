<template>
  <div>
    <div id="mint">
      <div class="page-width">
        <suspense>
          <template #default>
            <Mint />
          </template>
          <template #fallback>
            <div class="loader">
              <Spinner />
            </div>
          </template>
        </suspense>
      </div>
    </div>

    <div id="video">
      <video class="video" src="../images/video.mp4" autoplay loop muted playsinline></video>
    </div>

    <div id="project" class="page-width section">
      <div class="column col-content"><h4>Harnessing the power of NFTs to drive social good.</h4>
        <p> The <a href="https://www.builtwithbitcoin.org" target="_blank">Built With Bitcoin Foundation</a> has partnered with
          <a href="https://satoshibles.com" target="_blank">Satoshibles</a> to create Built With NFT - a collection of unique NFTs that will drive an enormous amount of funds to humanitarian efforts.</p>
        <p>The NFT collection is powered by artwork from students at BWB campuses across Africa. From this artwork, 10,000 completely unique NFTs were generated and made available to mint.</p>
        <p>100% of proceeds and royalties will fund the Foundation’s on-going efforts to build schools and communities around the world. We believe this is the largest scale use of NFT technology for social good anywhere in the world.</p>
        <p>It is extremely empowering for these communities to see that their artwork can be used to generate long lasting, sustainable outcomes for other communities all around the world.</p>
        <div id="partners"><p>Partners:</p>
          <div class="links">
            <a href="https://www.builtwithbitcoin.org" target="_blank"><img src="../images/partner-bwb.jpg" alt="Built With Bitcoin"></a>
            <a href="https://satoshibles.com" target="_blank"><img src="../images/partner-satoshibles.jpg" alt="Satoshibles"></a>
            <a href="https://stxnft.com" target="_blank"><img src="../images/partner-stxnft.jpg" alt="STXNFT"></a>
          </div>
        </div>
      </div>
      <div class="column example-img"><img src="../images/example.png" alt="NFT Example"></div>
    </div>

    <div id="team" style="background: rgb(230, 241, 248); padding-top: 40px">
      <div class="page-width section"><h4>The Team</h4>
        <div style="margin-top: 10px;"> Built With Bitcoin + Satoshibles + STXNFT</div>
        <p>The Built With Bitcoin Foundation is a humanitarian organization devoted to creating equitable opportunity by providing clean water, access to quality education, sustainable farming, and humanitarian support - all powered by Bitcoin and cryptocurrencies</p>
        <p>The team behind the successful NFT project Satoshibles is excited and honoured to be able to provide the technical know-how for the project, enabling the transformation from physical artwork to digital NFT.</p>
        <p>Together we created the Built with NFT project, leveraging the power of unique NFTs and empowering the children of BWB schools in Africa to pay-it-forward, creating a wave of positive impact reaching far across the globe.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Mint from '@/js/components/Mint';

export default {
  name: 'Home',
  components: {
    Mint,
  }
};
</script>
