import { ethers } from 'ethers';
import Abi from '../abi';
import { getSigner } from './getSigner';
import { ethContractAddress } from '@/main';

export const getEthContract = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await getSigner();
    const contract = new ethers.Contract(ethContractAddress, Abi, provider);

    return contract.connect(signer);
};
