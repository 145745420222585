<template>
  <router-link to="/#mint" id="top-bar">
    <div class="content">
      <span class="tag">NEW</span>
      <span>You can now mint your NFT on Bitcoin via Stacks!</span>
    </div>
  </router-link>
  <div>
    <div id="header">
      <div class="header-content page-width">
        <router-link to="/" class="logo">
          <img class="logo" src="../../images/logo.svg" alt="Built With NFT">
        </router-link>
        <nav id="nav">
          <a v-if="state.nfts.length > 0" class="gallery-button" @click="state.showGalleryModal = true">Your NFTs ({{ state.nfts.length }})</a>
          <router-link to="/#project">The Project</router-link>
          <router-link to="/#team">Team</router-link>
          <router-link to="/faq">FAQ</router-link>
          <router-link to="/help">Need Help?</router-link>
        </nav>
        <suspense>
          <template #default>
            <Wallet />
          </template>
          <template #fallback>
            <div class="loader">
              <Spinner />
            </div>
          </template>
        </suspense>
      </div>
      <div v-if="$route.name === 'home'" class="hero-section page-width">
        <div class="hero-text"><h1><span>We believe NFTs can <br> be a tool for <strong>social good.</strong></span></h1>
          <span class="progress">Powered by <a href="https://satoshibles.com" target="_blank">Satoshibles</a> &amp; <a href="https://www.builtwithbitcoin.org" target="_blank">The Built With Bitcoin Foundation</a></span>
        </div>
        <img src="../../images/dots.png" alt="Social Good">
      </div>
    </div>
    <StudentCards v-if="$route.name === 'home'" />
    <div class="content">
      <router-view />
      <div id="footer">
        <div class="page-width section">
          <div><p> A project by <a href="https://satoshibles.com" target="_blank">Satoshibles</a> and the <a href="https://www.builtwithbitcoin.org/" target="_blank">Built With Bitcoin Foundation</a>
          </p>
            <p>© 2022 Built With NFT. <span class="break">All Rights Reserved.</span></p>
            <p><a href="#">Terms &amp; Conditions</a> | <a href="#">Privacy Policy</a> | <a href="#">FAQ</a></p>
          </div>
          <div id="socials"><a href="https://twitter.com/builtwithnft" target="_blank">
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-twitter"></use>
            </svg>
          </a><a href="https://discord.gg/7Wm9Jg8MkW" target="_blank">
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-discord"></use>
            </svg>
          </a><a href="https://opensea.io/collection/builtwithnft" target="_blank">
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-opensea"></use>
            </svg>
          </a><a href="https://etherscan.io/address/0x15870e67DE672d1dbdCE72f4F4e7365CDDe22DaF" target="_blank">
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-etherscan"></use>
            </svg>
          </a></div>
        </div>
      </div>
    </div>
  </div>
  <div class="preload-images" v-if="preload">
    <img v-for="id of state.nfts" :key="id" :src="`https://api.builtwithnft.org/token/${id}/small/image.jpg`" :alt="`Built With NFT #${id}`">
    <img src="../../images/mint-1.png" alt="Mint 1 NFT">
    <img src="../../images/mint-5.png" alt="Mint 5 NFTs">
    <img src="../../images/mint-10.png" alt="Mint 10 NFTs">
    <img src="../../images/mint-25.png" alt="Mint 25 NFTs">
    <img src="../../images/mint-50.png" alt="Mint 50 NFTs">
    <img v-for="index in 15" :key="index" :src="getHeadImgUrl(index)" alt="Student Artwork">
  </div>
</template>

<script>
import Wallet from '@/js/components/Wallet';
import Spinner from '@/js/components/Spinner';
import { state, userSession } from '@/main';
import { updateStacksData } from '@/js/helpers/stacksLogin';
import StudentCards from '@/js/components/StudentCards';
import { getHeadImgUrl } from '@/js/helpers/getHeadImgUrl';
import { ref } from 'vue';

export default {
  components: {
    StudentCards,
    Wallet,
    Spinner
  },
  setup() {
    const preload = ref(false);

    if (userSession.isUserSignedIn()) {
      updateStacksData();
    }

    setTimeout(() => {
      preload.value = true;
    }, 2000);

    return {
      state,
      preload,
      getHeadImgUrl
    };
  }
};
</script>

<style lang="scss">
@import "../../css/app.scss";
</style>