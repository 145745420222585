export const getExchangeData = async () => {
    const data = await fetch('https://api.lnswap.org:9007/getpairs', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).catch(err => console.error(err)).then(res => res.json());

    if (data?.errors?.length) {
        console.error(`Error fetching LNSwap Pair info: ${data.errors[0].message}`);
        return [];
    }

    return data;
};