import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { callReadOnlyFunction, cvToJSON } from '@stacks/transactions';
import { isDev, stacksContractAddress } from '../../main';

export const getStacksProgress = async () => {
    const activeNetwork = isDev ? new StacksTestnet() : new StacksMainnet();

    const functionArgs = [];

    const options = {
        contractAddress: stacksContractAddress.split('.')[0],
        contractName: stacksContractAddress.split('.')[1],
        functionName: 'get-last-token-id',
        network: activeNetwork,
        functionArgs,
        senderAddress: isDev ? 'ST1JCPNPAMAQJ364AFHPTW3HY7X0HYZ3TJ0FBHDSE' : 'SP2W7TFWADF6QX5PEZWSWZT6Q0CZJWKJXBKB5R9CV'
    };

    const res = await callReadOnlyFunction(options);

    return Number(cvToJSON(res).value.value) - 5000;
};
