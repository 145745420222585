export const getUsdRates = async () => {
    const data = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=blockstack,ethereum,bitcoin&vs_currencies=usd', {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).catch(err => console.error(err)).then(res => res.json());

    if (data?.errors?.length) {
        console.error(`Error fetching CoinGecko BTCUSD info: ${data.errors[0].message}`);
        return [];
    }

    return data;
};