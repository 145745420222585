import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
    },
    {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "about" */ '../views/Help.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {el: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    },
});

export default router;
