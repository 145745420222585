<template>
    <div class="progress-bar">
        <div class="info">
            Minted: {{ current }} / {{ total }}
        </div>
        <div ref="label" class="label"></div>
        <div ref="bar" class="value" :style="width" />
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { state } from '@/main';

export default {
    props: {
        current: Number,
        total: Number,
    },
    setup(props) {
        const bar = ref(null);
        const label = ref(null);

        const width = computed(() => {
            const percentage = (100 / props.total * props.current).toFixed(0);

            return {width: `${percentage}%`};
        });

        return {
            bar,
            state,
            label,
            width
        };
    }
};
</script>
