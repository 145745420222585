<template>
  <div id="the-project" class="info">
    <h2>Built With NFT Project</h2>
    <div id="progress">
      <div class="page-width">
        <suspense>
          <template #default>
            <MintProgress />
          </template>
          <template #fallback>
            <ProgressBar
                :current="0"
                :total="10000"
            />
          </template>
        </suspense>
      </div>
    </div>
    <div>
      <h4 style="margin: 50px;"><span class="underline">100%</span> of the proceeds goes back to Built With Bitcoin!</h4>
      <p>10,000 unique NFTs, hand drawn by students from Built With Bitcoin schools across Africa.</p>
      <p>The collection has 5000 available to mint on Bitcoin via Stacks and 5000 on Ethereum.</p>
      <p v-if="section === 'intro'" class="start-button">
        <button class="button primary" @click="section = 'minting'">Start Here</button>
      </p>
    </div>
  </div>

  <div id="mint-wrapper" v-if="section === 'minting'">
    <div class="mint-info mint-column">
      <ul>
        <li class="q-1" :class="{active: amount >= 1 && amount <= 3}" @click="handleInfoClick(1)">
          <span class="mint-label"><span class="value">1</span> Mint</span>
          <span>One mint could provide a student with uniforms, backpack, desk, and chair.</span>
        </li>
        <li class="q-5" :class="{active: amount === 5}" @click="handleInfoClick(5)">
          <span class="mint-label"><span class="value">5</span> Mints</span>
          <span>Five mints could provide an entire classroom with electricity through solar.</span>
        </li>
        <li class="q-10" :class="{active: amount === 10}" @click="handleInfoClick(10)">
          <span class="mint-label"><span class="value">10</span> Mints</span>
          <span>Ten mints could provide the salary of a teacher for an entire school year.</span>
        </li>
        <li class="q-25" :class="{active: amount === 25}" @click="handleInfoClick(25)">
          <span class="mint-label"><span class="value">25</span> Mints</span>
          <span>Twenty-five mints could build vital infrastructure such as restrooms.</span>
        </li>
        <li class="q-50" :class="{active: amount === 50}" @click="handleInfoClick(50)">
          <span class="mint-label"><span class="value">50</span> Mints</span>
          <span>Fifty mints could provide water for the student’s entire community.</span>
        </li>
      </ul>
    </div>
    <div class="mint-column mint-image">
      <img v-if="amount >= 1 && amount <= 3" src="../../images/mint-1.png" alt="Mint 1 NFT">
      <img v-if="amount === 5" src="../../images/mint-5.png" alt="Mint 5 NFTs">
      <img v-if="amount === 10" src="../../images/mint-10.png" alt="Mint 10 NFTs">
      <img v-if="amount === 25" src="../../images/mint-25.png" alt="Mint 25 NFTs">
      <img v-if="amount === 50" src="../../images/mint-50.png" alt="Mint 50 NFTs">
    </div>
    <div v-if="isPaused" class="mint-now mint-column tabs">
      <div v-if="activeTab === 'mint'" class="tab-item">
        <div class="mint-row" style="text-align: center;">
          <h4 style="margin-top: 10px">Minting Paused</h4>
          <p>Minting has been temporarily paused.</p>
          <p>Please try again later</p>
        </div>
      </div>
    </div>
    <div v-else class="mint-now mint-column tabs" :class="{'has-header': state.nfts.length > 0}">
      <div v-if="activeTab === 'mint'" class="tab-item">
        <div class="mint-row">
          <p class="title">How many you would like mint?</p>
          <ul class="tab-buttons">
            <li v-for="(quantity, index) of filteredAmounts" :key="quantity">
              <label class="tab-button" :class="{disabled: isFormDisabled, selected: amount.toString() === index }">
                <input type="radio" name="amount" :disabled="isFormDisabled" :value="parseInt(index)" v-model.number="amount" @click="updateAmount(index)">
                {{ index }}
              </label>
            </li>
          </ul>
        </div>

        <div class="mint-row">
          <p class="title">How would you like to pay?</p>
          <ul class="tab-buttons full">
            <li>
              <label class="tab-button" :class="{disabled: isFormDisabled, selected: paymentType === 'btc' }">
                <input type="radio" name="payment-type" :disabled="isFormDisabled" :value="paymentType" v-model="paymentType" @click="handleBtcPaymentChange">
                <span>Bitcoin (Lightning)</span>
                <span class="tag">NEW</span>
                <span class="symbol">
                  <Icon name="bitcoin" />
                </span>
              </label>
            </li>
            <li>
              <label class="tab-button" :class="{disabled: isFormDisabled, selected: paymentType === 'stx' }">
                <input type="radio" name="payment-type" :disabled="isFormDisabled" :value="paymentType" v-model="paymentType" @click="paymentType = 'stx'">
                <span>Stacks (STX)</span>
                <span class="tag">NEW</span>
                <span class="symbol">
                  <Icon name="stacks" />
                </span>
              </label>
            </li>
            <li>
              <label class="tab-button" :class="{disabled: isFormDisabled, selected: paymentType === 'eth' }">
                <input type="radio" name="payment-type" :disabled="isFormDisabled" :value="paymentType" v-model="paymentType" @click="paymentType = 'eth'">
                <span>Ethereum (ETH)</span>
                <span class="symbol">
                  <Icon name="eth" />
                </span>
              </label>
            </li>
          </ul>
        </div>

        <div class="payment-info" v-if="paymentType === 'stx'">
          <p class="price-row">
            <label>Price</label>
            <span class="value">{{ getPrice() }} STX</span>
          </p>
          <p class="price-row">
            <label>Total</label>
            <span class="value total">{{ total }} STX (${{ usdStacksTotal }})</span>
          </p>
        </div>

        <div class="payment-info" v-if="paymentType === 'btc'">
          <p class="price-row">
            <label>Price</label>
            <span class="value">{{ btcPrice }} BTC</span>
          </p>

          <p class="price-row">
            <label>Total</label>
            <span class="value total">{{ btcTotal }} BTC (${{ usdTotal }})</span>
          </p>
        </div>

        <div class="payment-info" v-if="paymentType === 'eth'">
          <p class="price-row">
            <label>Price</label>
            <span class="value">{{ ethPrice }} ETH</span>
          </p>

          <p class="price-row">
            <label>Total</label>
            <span class="value total">{{ totalEth }} ETH (${{ totalEthPrice }})</span>
          </p>
        </div>

        <div class="button-wrap">
          <div v-if="paymentType !== 'eth'" class="button-container">
            <div v-if="state.stacksLoggedIn" class="mint-button-wrap">
              <button class="button primary mint-button" @click="mint" :disabled="isFormDisabled || showInsufficientBalance">
                <span v-if="showInsufficientBalance">Insufficient Balance</span>
                <span v-else>Mint Now</span>
              </button>
            </div>
            <button v-else class="button mint-button" @click="stacksLogin">
              <span>Connect Wallet</span>
            </button>
          </div>
          <div v-else class="button-container">
            <div v-if="state.isInstalled">
              <div v-if="state.ethConnected" class="mint-button-wrap">
                <button class="button primary mint-button" @click="mintEth" :disabled="isFormDisabled">
                  <span>Mint Now</span>
                </button>
              </div>
              <button v-else class="button mint-button" @click="connect">
                <span>Connect Wallet</span>
              </button>
            </div>
            <a v-else class="button mint-button" href="https://metamask.io/download" target="_blank">
              <span>Install MetaMask</span>
            </a>
          </div>

        </div>

        <button v-if="state.nfts.length > 0" class="button show-gallery" @click="state.showGalleryModal = true">
          <span>View Your NFTs ({{ state.nfts.length }})</span>
        </button>

        <div v-if="error !== ''" class="error">
          {{ error }}
        </div>
      </div>
      <div v-if="activeTab === 'gallery'" class="gallery-wrapper tab-item">
        <Gallery />
      </div>
    </div>
  </div>

  <Modal v-if="showModal" class-name="confirm-model" max-width="700px" @close="closeModal">
    <div v-if="txId !== ''">
      <img class="thanks-image" src="../../images/thanks.png" alt="Thank You">
      <h4>Your NFTs are on the way!</h4>
      <p>Your transaction has been successfully sent to the blockchain and is now being processed. You can go to the explorer to check if it has been confirmed.</p>

      <a :href="getExplorerLink(txId)" class="button primary" target="_blank">Click here to follow your transaction in the Explorer</a>
      <a :href="getMarketplaceLink(txId)" class="button" target="_blank">Click here to view on marketplace</a>

      <div class="note">
        <h4>What should I do next?</h4>
        <p>Why not join the <a href="https://discord.gg/7Wm9Jg8MkW">Satoshibles Discord</a> and say hello or send a tweet?</p>
        <a class="button" :href="tweet" target="_blank">Send a Tweet</a>
      </div>
    </div>
    <div class="loader" v-else>
      <Spinner size="large" />
    </div>
  </Modal>

  <Modal class-name="confirm-model pending-modal" v-if="state.pending.length > 0" max-width="800px" @close="closePendingModal">
    <img class="thanks-image" src="../../images/thanks.png" alt="Thank You">
    <h4>Your NFTs are on the way!</h4>
    <p>Your transaction has been successfully sent to the blockchain and is now being processed. You can go to the explorer to check if it has been confirmed.</p>

    <p>Please note, this process can take anywhere between 20-60 minutes to complete. Please check back later.</p>

    <ul>
      <li v-for="tx of state.pending" :key="tx.tx_id">
        <a :href="`https://explorer.stacks.co/txid/${tx.tx_id}?chain=mainnet`" target="_blank" class="button primary">View On Explorer</a>
      </li>
    </ul>

    <div class="note">
      <h4>What should I do next?</h4>
      <p>Why not join the <a href="https://discord.gg/7Wm9Jg8MkW">Satoshibles Discord</a> and say hello or send a tweet?</p>
      <a class="button" :href="tweet" target="_blank">Send a Tweet</a>
    </div>
  </Modal>

  <Modal class-name="gallery-modal" v-if="state.showGalleryModal" @close="state.showGalleryModal = false">
    <h4>Your NFTs ({{ state.nfts.length }})</h4>
    <Gallery />
  </Modal>
</template>

<script>
import { computed, ref } from 'vue';
import BigNum from "bn.js";
import { stacksLogin } from '@/js/helpers/stacksLogin';
import { openContractCall } from '@stacks/connect';
import { isDev, stacksContractAddress, state } from '@/main';
import { StacksMainnet, StacksTestnet } from '@stacks/network';
import { FungibleConditionCode, makeStandardSTXPostCondition } from '@stacks/transactions';
import { getExchangeData } from '@/js/helpers/getExchangeData';
import Modal from '@/js/components/Modal';
import { sleep } from '@/js/helpers/sleep';
import Gallery from '@/js/components/Gallery';
import ProgressBar from '@/js/components/ProgressBar';
import MintProgress from '@/js/components/MintProgress';
import { getUsdRates } from '@/js/helpers/getUsdRates';
import { refreshNftBalance } from '@/js/helpers/refreshNftBalance';
import Icon from '@/js/components/Icon';
import { ethers } from 'ethers';
import { getEthContract } from '@/js/helpers/getEthContract';
import Spinner from '@/js/components/Spinner';

const amounts = {
  1: 'claim',
  2: 'claim-two',
  3: 'claim-three',
  5: 'claim-five',
  10: 'claim-ten',
  25: 'claim-twentyfive',
  50: 'claim-fifty',
};

const getPrice = () => {
  return state.price / 1000000;
};

export default {
  components: {
    Spinner,
    Gallery,
    Modal,
    ProgressBar,
    Icon,
    MintProgress,
  },
  async setup() {
    const isPaused = ref(false);
    const showModal = ref(false);
    const txId = ref('');
    const activeTab = ref('mint');
    const section = ref('minting');
    const paymentType = ref('btc');
    const activeMintInfo = ref(1);
    const error = ref('');
    const rate = ref(0);
    const BTCUSD = ref(0);
    const STXUSD = ref(0);
    const ETHUSD = ref(0);
    const feePercent = ref(0);
    const amount = ref(1);

    const contractAddress = stacksContractAddress.split('.')[0];
    const contractName = stacksContractAddress.split('.')[1];
    const network = isDev ? new StacksTestnet() : new StacksMainnet();

    const updateAmount = (val) => {
      amount.value = parseInt(val);
    };

    const handleInfoClick = (val) => {
      if (paymentType.value === 'btc') {
        return;
      }

      amount.value = val;
    };

    const handleBtcPaymentChange = () => {
      paymentType.value = 'btc';
      amount.value = 1;
    };

    txId.value = new URL(window.location.href).searchParams.get('txid') || '';
    showModal.value = txId.value !== '';

    const total = computed(() => {
      return getPrice() * amount.value;
    });

    const getExplorerLink = (txId) => {
      if (txId.substring(0, 2) === '0x') {
        return `https://${isDev ? 'ropsten.' : ''}etherscan.io/tx/${txId}`;
      }

      return `https://explorer.stacks.co/txid/${txId}?chain=${isDev ? 'testnet' : 'mainnet'}`;
    };

    const getMarketplaceLink = (txId) => {
      if (txId.substring(0, 2) === '0x') {
        return `https://opensea.io/${state.ethUser}/builtwithnft?search[sortBy]=LISTING_DATE`;
      }

      return `https://stxnft.com/${state.stacksUser}`;
    };

    const btcPrice = computed(() => {
      const p = getPrice();
      const fee = p / 100 * feePercent.value;
      const val = (p / rate.value) + (fee / rate.value);

      return val.toFixed(8);
    });

    const updatePrices = async () => {
      const {pairs} = await getExchangeData();
      const btc = pairs['BTC/STX'];

      rate.value = btc.rate;
      feePercent.value = btc.fees.percentage;

      const rates = await getUsdRates();

      BTCUSD.value = rates.bitcoin.usd;
      STXUSD.value = rates.blockstack.usd;
      ETHUSD.value = rates.ethereum.usd;
    };

    const tweet = computed(() => {
      let message = `I am doing my part and giving back by minting NFTs designed by students from @BuiltWithNft ✌️\n\nA 100% donated project, check it out! ❤️\n\nMore info here:\nhttps://builtwithnft.org\n\n-\n`;

      return `https://twitter.com/intent/tweet?text=${encodeURI(message)}&related=builtwithnft&hashtags=nft,nfts,charity,Bitcoin,BTC,NFTcollection`;
    });

    if (isDev === false) {
      setInterval(async () => {
        await updatePrices();
      }, 60 * 1000);
    }

    await updatePrices();

    const isFormDisabled = computed(() => {
      return false;
      // return state.stacksLoggedIn === false;
    });

    const btcTotal = computed(() => {
      return (btcPrice.value * amount.value).toFixed(8);
    });

    const claim = () => {
      let standardFungiblePostCondition;

      const functionArgs = [];

      standardFungiblePostCondition = makeStandardSTXPostCondition(
          state.stacksUser,
          FungibleConditionCode.LessEqual,
          new BigNum(state.price * amount.value)
      );

      const options = {
        contractAddress: contractAddress,
        contractName: contractName,
        functionName: amounts[amount.value],
        network: network,
        functionArgs,
        postConditions: [standardFungiblePostCondition],
        appDetails: {
          name: "Built With NFT",
          icon: state.icon
        },
        onFinish: async (data) => {
          showModal.value = true;

          await sleep(3000);

          console.log(data);
          confirmTx(data.txId);
        },
        onCancel: (data) => {
          console.log(data);
        }
      };

      openContractCall(options);
    };

    const confirmTx = (tx) => {
      window.history.replaceState(null, null, '?txid=' + tx);
      txId.value = tx;
    };

    const callLnSwap = () => {
      window.lnswap(
          "swap",
          "triggerswap",
          state.stacksUser,
          total.value,
          stacksContractAddress,
          amounts[amount.value],
          "true"
      );
    };

    const closeModal = () => {
      txId.value = '';
      showModal.value = false;
      window.history.replaceState(null, null, '/');
    };

    window.onmessage = function (e) {
      if (e.data && e.data.target && e.data.target === 'lnswap') {
        showModal.value = true;
        console.log(e.data);
        confirmTx(e.data.data.txId);
      }
    };

    const usdPrice = computed(() => {
      return (BTCUSD.value * btcPrice.value).toFixed(2);
    });

    const usdTotal = computed(() => {
      return (BTCUSD.value * btcTotal.value).toFixed(2);
    });

    const usdStacksPrice = computed(() => {
      return (STXUSD.value * getPrice()).toFixed(2);
    });

    const usdStacksTotal = computed(() => {
      return (STXUSD.value * total.value).toFixed(2);
    });

    await refreshNftBalance();

    const contract = window.ethereum ? await getEthContract() : null;

    const ethPrice = 0.04;
    const DEFAULT_PRICE = ethers.utils.parseEther(ethPrice.toString());

    const finalPrice = computed(() => {
      return DEFAULT_PRICE.mul(Math.max(0, amount.value));
    });

    const mintEth = async () => {
      if (!window.ethereum) {
        return;
      }

      const data = await contract.mintTokens(
          amount.value,
          {value: finalPrice.value}
      ).catch(err => {
        console.log(err);
        error.value = 'Oops, something went wrong. Please reload the page and try again.';
      });

      if (!data.hash) {
        return;
      }

      showModal.value = true;

      await sleep(3000);

      console.log(data);
      confirmTx(data.hash);
    };

    const mint = async () => {
      switch (paymentType.value) {
        case 'btc':
          callLnSwap();
          return;

        case 'stx':
          claim();
          return;
      }
    };

    const showInsufficientBalance = computed(() => {
      if (paymentType.value === 'btc') {
        return false;
      }

      if (paymentType.value === 'eth') {
        return false;
      }

      return total.value >= state.userStacksBalance;
    });

    const updateAccount = async () => {
      const accounts = await window.ethereum.request({method: 'eth_accounts'});
      const connected = accounts.length > 0;

      state.ethConnected = connected;
      state.ethUser = connected ? accounts[0] : null;
    };

    const connect = async () => {
      try {
        await window.ethereum.request({method: 'eth_requestAccounts'});

        await updateAccount();

      } catch (error) {
        console.error(error);
      }
    };

    const totalEth = computed(() => {
      return ethPrice * amount.value;
    });

    const totalEthPrice = computed(() => {
      return (ETHUSD.value * totalEth.value).toFixed(2);
    });

    if (window.ethereum) {
      await updateAccount();
    }

    const filteredAmounts = computed(() => {
      return paymentType.value === 'btc' ? {1: 'claim'} : amounts;
    });

    const closePendingModal = () => {
      state.pending = [];
      state.pendingModalClosed = true;
    };

    return {
      filteredAmounts,
      connect,
      totalEth,
      totalEthPrice,
      showInsufficientBalance,
      mint,
      showModal,
      getMarketplaceLink,
      state,
      error,
      getPrice,
      total,
      usdStacksPrice,
      usdStacksTotal,
      getExplorerLink,
      btcTotal,
      amount,
      callLnSwap,
      ethPrice,
      stacksLogin,
      btcPrice,
      closeModal,
      updateAmount,
      isPaused,
      txId,
      claim,
      mintEth,
      isFormDisabled,
      handleInfoClick,
      usdPrice,
      usdTotal,
      activeMintInfo,
      tweet,
      section,
      activeTab,
      paymentType,
      handleBtcPaymentChange,
      isDev,
      closePendingModal,
      amounts
    };
  }
};
</script>