import { showConnect } from '@stacks/connect';
import { isDev, state, userSession } from '@/main';
import { getShortStacksAddress } from '@/js/helpers/getShortStacksAddress';
import { refreshBalance } from '@/js/helpers/refreshBalance';
import { refreshNftBalance } from '@/js/helpers/refreshNftBalance';

export const updateStacksData = () => {
    state.userData = userSession.loadUserData() || null;
    state.stacksUser = isDev ? state.userData.profile.stxAddress.testnet : state.userData.profile.stxAddress.mainnet;
    state.stacksLoggedIn = true;
    state.stacksAddress = getShortStacksAddress(state.stacksUser);
};

export const stacksLogin = async () => {
    if (userSession.isSignInPending()) {
        userSession.handlePendingSignIn().then(() => {
            updateStacksData();
        });

        return;
    }

    showConnect({
        appDetails: {
            name: "Built With NFT",
            icon: state.icon,
        },
        redirectTo: "/",
        onCancel: () => {
            console.log('login cancelled');
        },
        onFinish: async () => {
            updateStacksData();
            await refreshBalance();
            await refreshNftBalance();
        },
        userSession: userSession,
    });
};