<template>
    <svg class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#svg-${name}`"></use>
    </svg>
</template>

<script>
export default {
    props: {
        name: String
    }
};
</script>
